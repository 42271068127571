import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { CameraMedia, ROI } from "helpers/interfaces";
import {
    calculateAbsoluteROIValues,
    calculateLineWidth,
    CustomLink,
    getImageFilename,
    getLocaleDateString,
    getTimeIn12h,
    getWeekDay,
    roiColor,
    roundOff
} from "helpers/utils";
import classNames from "classnames";

interface IProps {
    show: boolean;
    isSaving: boolean;
    onHide: () => void;
    showROI?: boolean;
    onSaveRegionsOfInterest: (regions: (ROIRectangle | ROI)[]) => void;
    imageSrc: string;
    imageInfo: CameraMedia;
    regions: ROI[];
    onNext: () => void;
    onPrev: () => void;
    onHdRequested: (index: number, imageId: number) => void;
    onImageSelected: (imageId: number) => void;
    toggleMediaLockStatus: (imageId: number) => void;
    isImageMarkedAsLocked: (imageId: number) => boolean;
    mode?: UserMode;
    isSelect: boolean;
    imageIndex: number;
}

type ROIRectangle = {
    relative_x: number;
    relative_y: number;
    relative_width: number;
    relative_height: number;
}

enum UserMode {
    VIEW = "view",
    HOVER = "hover",
    DRAW = "draw",
    MOVE = "move",
    RESIZE = "resize",
    ZOOM_PAN = "pan_zoom"
}

enum PointerType {
    NWSE_RESIZE = "nwse-resize",
    NESW_RESIZE = "nesw-resize",
    MOVE = "move",
    CROSSHAIR = "crosshair",
    EW_RESIZE = "ew-resize",
    NS_RESIZE = "ns-resize"
}

const getCursorForPointerPosition = (position: any) => {
    switch (position) {
        case "tl":
        case "br":
            return PointerType.NWSE_RESIZE;
        case "tr":
        case "bl":
            return PointerType.NESW_RESIZE;
        case "left":
        case "right":
            return PointerType.EW_RESIZE
        case "top":
        case "bottom":
            return PointerType.NS_RESIZE
        case "inside":
            return PointerType.MOVE;
        default:
            return "crosshair";
    }
};

const getModeForCursorPosition = (position: any): UserMode => {
    switch (position) {
        case "inside":
            return UserMode.MOVE;
        case "tl":
        case "br":
        case "tr":
        case "bl":
        case "top":
        case "bottom":
        case "left":
        case "right":
            return UserMode.RESIZE
        default:
            return UserMode.DRAW;
    }
}

const getResizedCoordinates = (clientX: number, clientY: number, pointerPosition: any, selectedROI: ROIRectangle) => {
    switch (pointerPosition) {
        case "top":
            return {
                relative_x: selectedROI.relative_x,
                relative_y: clientY,
                relative_width: selectedROI.relative_width,
                relative_height: roundOff((selectedROI.relative_height + (selectedROI.relative_y - clientY)))
            };
        case "left":
            return {
                relative_x: clientX,
                relative_y: selectedROI.relative_y,
                relative_width: roundOff((selectedROI.relative_width + (selectedROI.relative_x - clientX))),
                relative_height: selectedROI.relative_height
            };
        case "right":
            return {
                relative_x: selectedROI.relative_x,
                relative_y: selectedROI.relative_y,
                relative_width: roundOff(clientX - selectedROI.relative_x),
                relative_height: selectedROI.relative_height
            };
        case "bottom":
            return {
                relative_x: selectedROI.relative_x,
                relative_y: selectedROI.relative_y,
                relative_width: selectedROI.relative_width,
                relative_height: roundOff(clientY - selectedROI.relative_y)
            };
        case "tl":
            return {
                relative_x: clientX,
                relative_y: clientY,
                relative_width: roundOff((selectedROI.relative_width + (selectedROI.relative_x - clientX))),
                relative_height: roundOff((selectedROI.relative_height + (selectedROI.relative_y - clientY)))
            };
        case "tr":
            return {
                relative_x: selectedROI.relative_x,
                relative_y: clientY,
                relative_width: roundOff(clientX - selectedROI.relative_x),
                relative_height: roundOff((selectedROI.relative_height + (selectedROI.relative_y - clientY)))
            };
        case "bl":
            return {
                relative_x: clientX,
                relative_y: selectedROI.relative_y,
                relative_width: roundOff((selectedROI.relative_width + (selectedROI.relative_x - clientX))),
                relative_height: roundOff(clientY - selectedROI.relative_y)
            };
        case "br":
            return {
                relative_x: selectedROI.relative_x,
                relative_y: selectedROI.relative_y,
                relative_width: roundOff(clientX - selectedROI.relative_x),
                relative_height: roundOff(clientY - selectedROI.relative_y)
            };
        // Leave as is
        default:
            return {...selectedROI}
    }
}

const ImagePopupModal = (props: IProps) => {
    const [roiReferenceImage, setRoiReferenceImage] = useState<HTMLImageElement>();
    const canvasRef = useRef(null);
    const [roiRectangles, setRoiRectangles] = useState<(ROI | ROIRectangle)[]>([...props.regions]);
    const [mode, setMode] = useState(UserMode.VIEW);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [dragOffsetX, setDragOffsetX] = useState(0);
    const [dragOffsetY, setDragOffsetY] = useState(0);
    const [highlightedROIRectangle, setHighlightedROIRectangle] = useState<ROIRectangle | null>(null);
    const [resizingDirection, setResizingDirection] = useState<string | null>(null);
    const [showROI, setShowROI] = useState(true);
    const [showDateOverlay, setShowDateOverlay] = useState(false);
    const [zoom, setZoom] = useState(1);
    const [panX, setPanX] = useState(0);
    const [panY, setPanY] = useState(0);
    const [prevPanX, setPrevPanX] = useState(0);
    const [prevPanY, setPrevPanY] = useState(0);
    const [isPanning, setIsPanning] = useState(false);

    const loadImage = () => {
        const image = new Image()
        image.src = props.imageSrc
        image.onload = () => {
            setRoiReferenceImage(image);
            setupCanvas(image);
        }
    }

    useEffect(() => {
        setupCanvas()
    }, [roiRectangles, showROI, zoom]);

    useEffect(() => {
        loadImage();
    }, [props.imageSrc, props.show]);

    const setupCanvas = (bgImage?: HTMLImageElement) => {
        const canvas: any = canvasRef.current;
        if (!canvas) {
            return;
        }
        const canvasBgImage = bgImage ? bgImage : roiReferenceImage
        if (!canvasBgImage) {
            return;
        }
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
        const imageWidth = canvasBgImage.width;
        const imageHeight = canvasBgImage.height;

        canvas.width = imageWidth;
        canvas.height = imageHeight
        canvas.style.cursor = ""

        ctx.save()
        const minPanX = imageWidth / zoom - imageWidth
        const minPanY = imageHeight / zoom - imageHeight

        const absPanX = panX * imageWidth
        const absPanY = panY * imageHeight

        const maxPanX = Math.max(absPanX, minPanX)
        const maxPanY = Math.max(absPanY, minPanY)

        const translateX = Math.min(maxPanX, 0)
        const translateY = Math.min(maxPanY, 0)
        ctx.scale(zoom, zoom)
        ctx.translate(translateX, translateY)

        ctx.clearRect(0, 0, imageWidth, imageHeight);
        ctx.drawImage(canvasBgImage, 0, 0);
        if (showROI) {
            roiRectangles.forEach((roiRect) => {
                const {
                    roiX,
                    roiY,
                    roiWidth,
                    roiHeight
                } = calculateAbsoluteROIValues(roiRect, imageWidth, imageHeight);
                ctx.strokeStyle = roiColor;
                ctx.lineWidth = calculateLineWidth(imageHeight)
                ctx.strokeRect(roiX, roiY, roiWidth, roiHeight);
            });
        }
        ctx.restore()
    }

    const setRoiBorderColor = (rectangle: any, borderColor = roiColor) => {
        const canvas: any = canvasRef.current;
        if (!canvas || !rectangle || !showROI) {
            return;
        }

        const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
        const {
            roiX,
            roiY,
            roiWidth,
            roiHeight
        } = calculateAbsoluteROIValues(rectangle, canvas.width, canvas.height);
        ctx.save();
        ctx.strokeStyle = borderColor;
        ctx.lineWidth = calculateLineWidth(canvas.height);
        ctx.strokeRect(roiX, roiY, roiWidth, roiHeight);
    }

    useEffect(() => {
        setRoiBorderColor(highlightedROIRectangle, 'red');
    }, [highlightedROIRectangle]);

    const handleMouseDown = (event: any) => {
        if (mode === UserMode.VIEW) {
            return;
        }
        const {offsetX, offsetY} = event.nativeEvent;
        const canvas: any = canvasRef.current;
        const relStartX = roundOff((offsetX / canvas.getBoundingClientRect().width))
        const relStartY = roundOff((offsetY / canvas.getBoundingClientRect().height))

        if (mode === UserMode.ZOOM_PAN) {
            if (zoom <= 1) {
                return;
            }
            setIsPanning(true);
            setPrevPanX(relStartX);
            setPrevPanY(relStartY);
            return;
        }

        const {position: pointerPosition, roi: highlightedROI} = getRoiAtPointerPosition(relStartX, relStartY)
        const drawMode = getModeForCursorPosition(pointerPosition)

        setHighlightedROIRectangle(_ => highlightedROI)
        setMode(_ => getModeForCursorPosition(pointerPosition));
        setResizingDirection(_ => pointerPosition);

        if (drawMode === UserMode.DRAW) {
            setStartX(relStartX);
            setStartY(relStartY);
            setDragOffsetX(0)
            setDragOffsetY(0)
        }

        if (drawMode === UserMode.MOVE) {
            if (!highlightedROI) {
                return;
            }
            setRoiRectangles(prevRoiRectangles => {
                const idx = prevRoiRectangles.findIndex(roi => {
                    return (roi.relative_x === highlightedROI.relative_x &&
                        roi.relative_y === highlightedROI.relative_y &&
                        roi.relative_width === highlightedROI.relative_width &&
                        roi.relative_height === highlightedROI.relative_height);
                })
                if (idx !== -1) {
                    prevRoiRectangles.splice(idx, 1);
                }
                return [...prevRoiRectangles];
            });

            setDragOffsetX(roundOff(relStartX - highlightedROI.relative_x));
            setDragOffsetY(roundOff(relStartY - highlightedROI.relative_y));

        }
        if (drawMode === UserMode.RESIZE) {
            if (!highlightedROI) {
                return;
            }
            // Remove active rect from list of rectangle. Will be added back once resizing is complete
            const idx = roiRectangles.findIndex(roi => {
                return (roi.relative_x === highlightedROI.relative_x &&
                    roi.relative_y === highlightedROI.relative_y &&
                    roi.relative_width === highlightedROI.relative_width &&
                    roi.relative_height === highlightedROI.relative_height);
            })
            if (idx >= 0) {
                roiRectangles.splice(idx, 1);
            }
            setRoiRectangles(_ => [...roiRectangles]);
        }
    }

    const handleMouseMove = (event: any) => {
        if (mode === UserMode.VIEW) {
            return;
        }
        const {offsetX, offsetY} = event.nativeEvent;
        const canvas: any = canvasRef.current;
        const relDragOffsetX = roundOff((offsetX / canvas.getBoundingClientRect().width))
        const relDragOffsetY = roundOff((offsetY / canvas.getBoundingClientRect().height))

        if (mode === UserMode.ZOOM_PAN) {
            if (isPanning) {
                const deltaX = relDragOffsetX - prevPanX;
                const deltaY = relDragOffsetY - prevPanY;
                setPanX((prevPanX) => prevPanX + deltaX);
                setPanY((prevPanY) => prevPanY + deltaY);
                setPrevPanX(relDragOffsetX);
                setPrevPanY(relDragOffsetY);
                setupCanvas();
            }
            return;
        }

        // Check if pointer is over any rectangle
        const {position: pointerPosition, roi: highlightedROI} = getRoiAtPointerPosition(relDragOffsetX, relDragOffsetY)
        canvas.style.cursor = getCursorForPointerPosition(pointerPosition);

        // For any mode other than move, update
        if (mode === UserMode.HOVER) {
            setHighlightedROIRectangle(prevRoI => {
                if (!highlightedROI) {
                    // Reset color changes to previous rectangle
                    setRoiBorderColor(prevRoI);
                }
                return highlightedROI
            });
        }

        if (mode === UserMode.DRAW) {
            canvas.style.cursor = PointerType.CROSSHAIR;
            setDragOffsetX(relDragOffsetX);
            setDragOffsetY(relDragOffsetY);
            redrawCanvas();
        }

        if (mode === UserMode.MOVE) {
            if (!highlightedROIRectangle) {
                return
            }

            canvas.style.cursor = PointerType.MOVE;
            const activeRect = {
                ...highlightedROIRectangle,
                relative_x: roundOff(relDragOffsetX - dragOffsetX),
                relative_y: roundOff(relDragOffsetY - dragOffsetY)
            }
            // Update selected
            setHighlightedROIRectangle(_ => activeRect);
            redrawCanvas();
        }

        if (mode === UserMode.RESIZE) {
            if (!highlightedROIRectangle) {
                return
            }
            canvas.style.cursor = getCursorForPointerPosition(resizingDirection);
            const resizedROI = getResizedCoordinates(relDragOffsetX, relDragOffsetY, resizingDirection, highlightedROIRectangle);
            setHighlightedROIRectangle(_ => resizedROI);
            redrawCanvas();
        }

    }

    const handleMouseUp = (event: any) => {
        setIsPanning(false);
        if ([UserMode.VIEW, UserMode.ZOOM_PAN].includes(mode)) {
            return;
        }
        const {offsetX, offsetY} = event.nativeEvent;
        const canvas: any = canvasRef.current;
        const relEndX = roundOff(((offsetX / canvas.getBoundingClientRect().width) - startX))
        const relEndY = roundOff(((offsetY / canvas.getBoundingClientRect().height) - startY))

        if (mode === UserMode.DRAW) {
            setRoiRectangles(prev => [
                ...prev,
                {
                    relative_x: startX,
                    relative_y: startY,
                    relative_width: relEndX,
                    relative_height: relEndY
                }
            ]);
            redrawCanvas();
        }

        if ([UserMode.MOVE, UserMode.RESIZE].includes(mode)) {
            if (!highlightedROIRectangle) {
                return;
            }
            roiRectangles.push({...highlightedROIRectangle})
            setRoiRectangles(_ => [...roiRectangles]);
            redrawCanvas();
        }

        // Reset
        setShowROI(true);
        setMode(UserMode.HOVER);
        setHighlightedROIRectangle(null);
        setResizingDirection(_ => null);
    };

    const redrawCanvas = () => {
        // Redraw all previous rectangles
        setupCanvas();

        const canvas: any = canvasRef.current;
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d');

        if (mode === UserMode.DRAW) {
            const diffWidth = roundOff((dragOffsetX - startX));
            const diffHeight = roundOff((dragOffsetY - startY));

            const roiRect: ROIRectangle = {
                relative_x: startX,
                relative_y: startY,
                relative_width: diffWidth <= 0 ? 0 : diffWidth,
                relative_height: diffHeight <= 0 ? 0 : diffHeight
            }
            const {
                roiX,
                roiY,
                roiWidth,
                roiHeight
            } = calculateAbsoluteROIValues(roiRect, canvas.width, canvas.height);

            ctx.strokeStyle = roiColor;
            ctx.lineWidth = calculateLineWidth(canvas.height);
            ctx.beginPath();
            ctx.rect(roiX, roiY, roiWidth, roiHeight);
            ctx.stroke();
        }

        if ([UserMode.MOVE, UserMode.RESIZE].includes(mode)) {
            const {
                roiX,
                roiY,
                roiWidth,
                roiHeight
            } = calculateAbsoluteROIValues(highlightedROIRectangle, canvas.width, canvas.height);

            ctx.strokeStyle = 'red';
            ctx.lineWidth = calculateLineWidth(canvas.height);
            ctx.beginPath();
            ctx.rect(roiX, roiY, roiWidth, roiHeight);
            ctx.stroke();
        }
    };

    const nearPoint = (cursorX: number, cursorY: number, roiX: number, roiY: number, name: string, max_distance = 0.005) => {
        return Math.abs(cursorX - roiX) < max_distance && Math.abs(cursorY - roiY) < max_distance ? name : null;
    };

    const distance = (a: any, b: any) => roundOff(Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2)));

    const nearLine = (cursorX: number, cursorY: number, x1: number, y1: number, x2: number, y2: number, maxDistance = 0.001) => {
        const a = {x: x1, y: y1};
        const b = {x: x2, y: y2};
        const c = {x: cursorX, y: cursorY};
        const offset = distance(a, b) - (distance(a, c) + distance(b, c));
        return Math.abs(offset) < maxDistance;
    };

    const getCursorPositionWithinRoi = (cursorX: number, cursorY: number, roi: ROIRectangle) => {
        if (!roi) {
            return null;
        }
        const top = nearLine(cursorX, cursorY, roi.relative_x, roi.relative_y, roi.relative_x + roi.relative_width, roi.relative_y) ? "top" : null;
        const bottom = nearLine(cursorX, cursorY, roi.relative_x, roi.relative_y + roi.relative_height, roi.relative_x + roi.relative_width, roi.relative_y + roi.relative_height) ? "bottom" : null;
        const left = nearLine(cursorX, cursorY, roi.relative_x, roi.relative_y, roi.relative_x, roi.relative_y + roi.relative_height) ? "left" : null;
        const right = nearLine(cursorX, cursorY, roi.relative_x + roi.relative_width, roi.relative_y, roi.relative_x + roi.relative_width, roi.relative_y + roi.relative_height) ? "right" : null;
        const topLeft = nearPoint(cursorX, cursorY, roi.relative_x, roi.relative_y, "tl");
        const topRight = nearPoint(cursorX, cursorY, roi.relative_x + roi.relative_width, roi.relative_y, "tr");
        const bottomLeft = nearPoint(cursorX, cursorY, roi.relative_x, roi.relative_y + roi.relative_height, "bl");
        const bottomRight = nearPoint(cursorX, cursorY, roi.relative_x + roi.relative_width, roi.relative_y + roi.relative_height, "br");
        const inside = cursorX >= roi.relative_x && cursorX <= roi.relative_x + roi.relative_width && cursorY >= roi.relative_y && cursorY <= roi.relative_y + roi.relative_height ? "inside" : null;
        return top || left || right || bottom || topLeft || topRight || bottomLeft || bottomRight || inside;
    }

    const getRoiAtPointerPosition = (clientX: number, clientY: number): {
        position: string | null,
        roi: ROIRectangle | null
    } => {
        let position = null;
        let roi = null;
        for (const el of roiRectangles) {
            position = getCursorPositionWithinRoi(clientX, clientY, el);
            if (position !== null) {
                roi = {...el};
                break
            }
        }
        return {position, roi}
    }

    const saveRegionsOfInterest = () => {
        setMode(UserMode.VIEW);
        props.onSaveRegionsOfInterest(roiRectangles)
        const canvas: any = canvasRef.current;
        canvas.style.cursor = "";
    };

    const clearAllRectangles = () => {
        setRoiRectangles(_ => [])
        redrawCanvas();
    };

    const onCancelDrawRoi = () => {
        setMode(UserMode.VIEW);
        const canvas: any = canvasRef.current;
        canvas.style.cursor = "";
    }

    const onZoomIn = () => {
        setMode(UserMode.ZOOM_PAN)
        setZoom(prevState => prevState * 1.2)
    }

    const onZoomOut = () => {
        setZoom(prevState => {
            const newScale = prevState / 1.2
            if (newScale <= 1) {
                resetZoom();
                return 1;
            }
            setMode(UserMode.ZOOM_PAN)
            return newScale
        })
    }

    const resetZoom = () => {
        setPanX(0)
        setPanY(0)
        setupCanvas()
        setMode(UserMode.VIEW)
    };

    const onResetZoom = () => {
        resetZoom();
        setZoom(1);
    }

    return (
        <React.Fragment>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="modal-dialog-centered modal-full-width">
                <div className="carousel-inner ribbon-box">
                    {props.isSelect && <div className="select-ribbon select-ribbon-primary">
                        <span>Select</span>
                    </div>}
                    <canvas
                        id="roiCanvas"
                        className="roi-canvas"
                        ref={canvasRef}
                        onPointerDown={handleMouseDown}
                        onPointerUp={handleMouseUp}
                        onPointerMove={handleMouseMove}>
                        <span>Could not load image</span>
                    </canvas>
                    {mode === UserMode.ZOOM_PAN && showDateOverlay &&
                        <div className="popup-date-overlay">
                            <span>{getLocaleDateString(props.imageInfo.captured_at)},</span>
                            <span>{getTimeIn12h(props.imageInfo.captured_at)},</span>
                            <span>{getWeekDay(props.imageInfo.captured_at)}</span>
                        </div>
                    }
                    {mode === UserMode.VIEW && (<React.Fragment>
                        <button
                            onClick={props.onPrev}
                            className="carousel-control-prev"
                            type="button"
                            data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                            onClick={props.onNext}
                            className="carousel-control-next"
                            type="button"
                            data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </React.Fragment>)
                    }
                </div>
                <Modal.Footer className="justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                        <span className="font-16">#{props.imageInfo.sequence}</span>
                        <span className="text-muted">{getTimeIn12h(props.imageInfo.captured_at)}</span>
                        {
                            <Form.Check className={classNames( "ms-3",mode === UserMode.ZOOM_PAN ? "visible": "invisible")}>
                                <Form.Switch>
                                    <Form.Check.Input
                                        defaultChecked={showDateOverlay}
                                        onClick={() => setShowDateOverlay(!showDateOverlay)}
                                        type="checkbox"
                                        role="switch"
                                        id="showOverlay"/>
                                    <Form.Check.Label
                                        htmlFor="showOverlay">Overlay Date on Zoomed Image
                                    </Form.Check.Label>
                                </Form.Switch>
                            </Form.Check>
                        }
                    </div>
                    <div className="d-flex align-items-center image-popup-icon-container">
                        <CustomLink onClick={() => props.onHdRequested(props.imageIndex, props.imageInfo.id)} title="Request HD" to="#" className="image-popup-btn">
                            <span>
                                {props.imageInfo.hd_request_status === "PENDING" ? (
                                        <span className="hd-pending text-warning">
                                            <span className="material-symbols-outlined image-popup-icon">hd</span>
                                        </span>
                                    ) :
                                    (
                                        <span

                                            className={classNames("material-symbols-outlined image-popup-icon",
                                                {
                                                    "text-muted": props.imageInfo.hd_file_path === null,
                                                    "text-success": props.imageInfo.hd_file_path !== null
                                                })}>hd
                                        </span>
                                    )}
                            </span>
                        </CustomLink>
                        <CustomLink
                            title="Download Image"
                            className="image-popup-btn"
                            to={props.imageInfo["download_url"]}
                            target="_target"
                            download={getImageFilename(props.imageInfo)}>
                                <span className={classNames("material-symbols-outlined image-popup-icon",
                                    {
                                        "text-primary": props.imageInfo["download_url"] !== undefined,
                                        "text-muted": props.imageInfo["download_url"] === undefined
                                    })}>download</span>
                        </CustomLink>
                        <CustomLink  title={!props.isSelect ? "Select Image" : "De-select Image"} className="image-popup-btn">
                                        <span
                                            onClick={() => props.onImageSelected(props.imageInfo.id)}
                                            className={classNames("material-symbols-outlined image-popup-icon",
                                                {
                                                    "text-muted": !props.isSelect,
                                                    "text-success": props.isSelect
                                                })}>{props.isSelect ? "select_check_box" : "check_box_outline_blank"}
                                        </span>
                        </CustomLink>
                        <CustomLink  title={!props.isImageMarkedAsLocked(props.imageInfo.id) ? "Lock Image" : "Unlock Image"} className="image-popup-btn">
                                        <span
                                            onClick={() => props.toggleMediaLockStatus(props.imageInfo.id)}
                                            className={classNames("material-symbols-outlined image-popup-icon lock_unlock_media_icon",
                                                {
                                                    "text-muted": !props.isImageMarkedAsLocked(props.imageInfo.id),
                                                    "text-success": props.isImageMarkedAsLocked(props.imageInfo.id)
                                                })}>{props.isImageMarkedAsLocked(props.imageInfo.id) ? "lock" : "lock_open"}
                                        </span>
                        </CustomLink>


                    </div>
                    <div className="d-flex align-items-center">
                        {[UserMode.ZOOM_PAN, UserMode.VIEW].includes(mode) &&
                            <React.Fragment>
                                <div className="image-popup-icon-container mt-2 me-3">

                                    <Button className="image-popup-btn" onClick={onZoomIn}>
                                        <span
                                            className="material-symbols-outlined image-popup-icon text-primary">zoom_in</span>
                                    </Button>
                                    <Button className="image-popup-btn" onClick={onZoomOut}>
                                        <span
                                            className="material-symbols-outlined image-popup-icon text-primary">zoom_out</span>
                                    </Button>
                                </div>
                            </React.Fragment>
                        }
                        {mode === UserMode.ZOOM_PAN &&
                            <Button
                                onClick={onResetZoom}
                                className="">Reset Zoom
                            </Button>
                        }
                        {![UserMode.VIEW, UserMode.ZOOM_PAN].includes(mode) &&
                            <React.Fragment>
                                <Button
                                    onClick={clearAllRectangles}
                                    variant="secondary-outline">
                                    Clear All
                                </Button>
                                <Button
                                    onClick={onCancelDrawRoi}
                                    variant="secondary-outline">
                                    Cancel
                                </Button>
                                <Button
                                    onClick={saveRegionsOfInterest}
                                    variant="primary">
                                    Save Regions
                                </Button>
                            </React.Fragment>
                        }
                        {mode === UserMode.VIEW &&
                            <Button
                                onClick={() => setMode(UserMode.HOVER)}
                                className="mr-3">Draw Regions
                            </Button>
                        }
                        {props.isSaving && <Spinner size="sm" className="m-2"/>}
                        <Form.Check className="ms-3">
                            <Form.Switch>
                                <Form.Check.Input
                                    defaultChecked={showROI}
                                    onClick={() => setShowROI(!showROI)}
                                    type="checkbox"
                                    role="switch"
                                    id="showRoi"/>
                                <Form.Check.Label
                                    htmlFor="showRoi">Show Regions
                                </Form.Check.Label>
                            </Form.Switch>
                        </Form.Check>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default ImagePopupModal;